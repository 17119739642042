import React, { useRef, useEffect } from "react"
import { useGLTF } from "@react-three/drei/core/useGLTF"
import { useFrame } from "react-three-fiber"
import { useLerp } from "lerp"

import { useSpring, a, config } from "react-spring/three"
import useDeviceOrientation from "react-hook-device-orientation"

import { CustomUniforms } from "../_helpers/CustomUniforms.js"
import { LineMaterial } from "../_shaders/threeMod/LineMaterial.js"
import { Galaxy } from "../_shaders/threeMod/Galaxy.js"
import { MoveJoint, MoveJointNeck } from "../_helpers/MoveJointMobile.js"

//
//
//  Original Code
//
//

export default function TorsoBoned({ mobileRightClicked, mobileLeftClicked, ...props }) {
  const isIOS = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  const deviceOrientation = useDeviceOrientation()
  let normalizedAlpha = deviceOrientation.alpha - 90
  let normalizedBeta = deviceOrientation.beta
  let normalizedGamma = deviceOrientation.gamma / 360

  if (!normalizedAlpha) normalizedAlpha = 0

  if (!normalizedBeta) normalizedBeta = 0

  let mouse = { x: 0, y: 0 }

  const group = useRef()
  const { nodes } = useGLTF("models/bonesd.glb")

  const aprops = useSpring({
    position: mobileLeftClicked ? [1.1, -7.0, -0.2] : mobileRightClicked ? [-1.3, -7.0, -0.2] : [-0.5, -6.0, -0.2],
    rotation: mobileLeftClicked ? [-0.2, -0.9, 0.01] : mobileRightClicked ? [-0.2, 0.7, -0.01] : [0.0, 0.01, -0.07],
    // rotation: desktopRightClicked || desktopLeftClicked ? ifRot : [0.0, 0, -0.1],

    config: {
      tension: 15,
      friction: 8,
    },
  })

  useFrame(({ clock, state, delta }) => {
    let x = (normalizedAlpha - 35) * normalizedBeta * 0.05
    let y = normalizedAlpha

    CustomUniforms.uTime.value = clock.getElapsedTime() * 0.15
    CustomUniforms.uTimeV.value = clock.getElapsedTime() * 0.15
    CustomUniforms.transTime.value = clock.getElapsedTime() * 0.075

    // UP THIS

    if (isIOS) {
      if (mobileLeftClicked) {
        mouse.x = 160
        mouse.y = 240
      }
      if (mobileRightClicked) {
        mouse.x = 320
        mouse.y = 480
      } else if (!mobileRightClicked && !mobileLeftClicked) {
        mouse.x = 160
        mouse.y = 480
      }
    } else {
      mouse.x = x * -4
      mouse.y = 400
    }

    MoveJoint(mouse, nodes.Bone004)
    MoveJointNeck(mouse, nodes.Bone003)
  })

  return (
    <group ref={group} {...props} dispose={null}>
      <a.group rotation={aprops.rotation} position={aprops.position} scale={[1.25, 1.25, 1.25]}>
        <primitive object={nodes.Bone} />
        <skinnedMesh
          material={Galaxy()}
          geometry={nodes.ParticleSettingscurve.geometry}
          skeleton={nodes.ParticleSettingscurve.skeleton}></skinnedMesh>
        <skinnedMesh
          material={LineMaterial(1.0)}
          geometry={nodes.ParticleSettingscurve_1.geometry}
          skeleton={nodes.ParticleSettingscurve_1.skeleton}></skinnedMesh>

        <skinnedMesh
          material={LineMaterial(1.0)}
          geometry={nodes.ParticleSettingscurve_2.geometry}
          skeleton={nodes.ParticleSettingscurve_2.skeleton}></skinnedMesh>
        <group>
          <skinnedMesh geometry={nodes.ParticleSettingscurve.geometry} skeleton={nodes.ParticleSettingscurve.skeleton}>
            <meshStandardMaterial color={"#301934"} skinning wireframe />
          </skinnedMesh>
        </group>
      </a.group>
    </group>
  )
}

useGLTF.preload("models/bonesd.glb")
