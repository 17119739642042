import React, { useRef, useEffect, useState } from "react"
import { Box } from "@react-three/drei"
import { useFrame, useThree } from "react-three-fiber"
import { useSpring, a, config } from "react-spring/three"
import Phone from "../model/Phone.js"
import Title from "../elements/TitleR.js"
import { COLORS } from "../_helpers/Colors.js"
import { WaveFog } from "../_shaders/threeMod/WaveFog.js"

import { Donut } from "../_shaders/threeMod/Donut.js"

const APhone = a(Phone)
const ABox = a(Box)
const ATitle = a(Title)

const isIOS = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

const isRealIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent)

function BoxL({ mobileRightClicked, mobileLeftClicked, desktopRightHovered, desktopLeftClicked, desktopRightClicked }) {
  const group = useRef()
  const { viewport } = useThree()
  const [hovered, setHovered] = useState(false)

  let isPos, isBasePos, phoneScale, phoneBaseScale, phoneRot, phoneBaseRot, sTrans, sTransBase

  isPos = [viewport.width / 2.5, -0.5, -2]
  isBasePos = [1, -10, -3]
  phoneRot = [1.5, -0.3, 0.7]
  phoneBaseRot = [1.7, -0.1, 6.0]
  phoneBaseScale = [0.5, 0.5, 0.5]
  phoneScale = [1.25, 1.35, 1.35]
  sTrans = 0.1
  sTransBase = 0.8

  if (desktopLeftClicked) {
    isPos = [viewport.width / 2.5, -0.5, -10]
    isBasePos = [viewport.width / 2.5, -0.5, -10]
  } else if (desktopRightHovered) {
    isPos = [viewport.width / 2.5, -0.5, -2]
    isBasePos = [viewport.width / 2.5, -0.5, -2]
    phoneBaseRot = [1.5, -0.3, 0.7]
    phoneBaseScale = [1.25, 1.35, 1.35]
    sTrans = 0.2
    sTransBase = 0.2
  } else if (desktopRightClicked) {
    isPos = [viewport.width / 1, -10, -10]
    isBasePos = [viewport.width / 1, -10, -10]
  }

  const props = useSpring({
    // Phone
    boxPosition: desktopRightClicked ? [-viewport.width, 0, -7] : [viewport.width * 1.03, 0, -8],
    // boxScale: desktopRightClicked ? [3.0, 1.25, 1.0] : [1.0, 1.0, 1.0],

    boxPosition: desktopRightClicked
      ? [-viewport.width, 0, -7]
      : mobileLeftClicked
      ? [viewport.width * 1.0, 0, -7.1]
      : mobileRightClicked
      ? [-viewport.width * 0.1, 0, -8.1]
      : isIOS
      ? [viewport.width * 0.8, 0, -8.1]
      : [viewport.width * 1.03, 0, -8],

    boxScale: desktopRightClicked
      ? [3.0, 1.25, 1.0]
      : mobileRightClicked
      ? [2.0, 1.0, 1.0]
      : mobileLeftClicked
      ? [0.0, 1.0, 1.0]
      : [1.0, 1.0, 1.0],

    boxRotation: isIOS ? [0.0, 0.0, 0.0] : [0.0, 0.0, -0.2],

    position: hovered ? isPos : isBasePos,
    scale: hovered ? phoneScale : phoneBaseScale,
    rotation: hovered ? phoneRot : phoneBaseRot,

    positionIOS: mobileRightClicked ? [viewport.width / 2, -1.5, -4] : [1, -5, -4],
    scaleIOS: mobileRightClicked ? [1.25, 1.25, 1.25] : [0.5, 0.5, 0.5],
    rotationIOS: mobileRightClicked ? [1.5, -0.3, 0.9] : [1.5, -0.3, 6.9],

    // Text
    color: hovered ? COLORS.black : "#0b0b0b",
    oColor: hovered ? COLORS.white : "#0b0b0b",
    outlineColor: hovered ? COLORS.white : "#0b0b0b",

    color: mobileRightClicked ? COLORS.black : "#0b0b0b",
    oColor: mobileRightClicked ? "pink" : "#0b0b0b",
    outlineColor: mobileRightClicked ? "pink" : "#0b0b0b",
    // Pushcom

    titlePosition:
      isIOS && !isRealIOS
        ? [viewport.width / 3.4, 2.4, -0.5]
        : isRealIOS
        ? [viewport.width / 3.4, 1.98, -0.5]
        : [viewport.width / 3, 0.75, -0.5],

    // lightpos
    lightPos: hovered ? [10, 10, -2] : [-4, -8, -16],
    lightPos02: hovered ? [10, 2, 0] : [-4, -8, -16],
    lightPos03: hovered ? [10, 4, 5] : [-4, -8, -16],
    intensity: hovered ? 0.05 : 0,

    //shader opacity
    shaderOpacity: hovered ? 0.8 : 0.8,
    fogC: hovered ? 12.0 : 0.0,
    shaderColorR: hovered ? 0.01 : 0.1,
    shaderColorG: hovered ? 0.01 : 0.1,
    shaderColorB: hovered ? 0.01 : 0.1,
    trans: hovered ? sTrans : sTransBase,
    mobiletrans: 0.2,

    //shader opacity
    shaderOpacity: 0.65,
    fogCmobile: hovered ? 45.0 : desktopRightClicked || mobileRightClicked ? -1.0 : 8.0,

    shaderColorR: 0.4,
    shaderColorG: 0.1,
    shaderColorB: 1.1,

    // General Config
    config: {
      tension: 16,
      friction: 8,
    },
  })

  return (
    <group ref={group} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
      <ABox
        material={
          isIOS
            ? WaveFog(props.shaderOpacity, props.fogCmobile, props.shaderColorR, props.shaderColorG, props.shaderColorB)
            : Donut(props.trans)
        }
        rotation={props.boxRotation}
        args={[viewport.width * 1.9, viewport.height * 4.5]}
        position={props.boxPosition}
        desktopRightHovered={desktopRightHovered}
        scale={props.boxScale}></ABox>

      {isIOS ? null : <APhone position={props.position} rotation={props.rotation} scale={props.scale} />}

      <APhone position={props.position} rotation={props.rotation} scale={props.scale} />

      {/* {isIOS ? (
        <Title
          color={props.oColor}
          outlineColor={props.outlineColor}
          content="Digital"
          position={[viewport.width / 3.4, 2.4, -0.5]}
          mobileRightClicked={mobileRightClicked}
        />
      ) : (
        
      )} */}
      <ATitle
        desktopRightClicked={desktopRightClicked}
        desktopLeftClicked={desktopLeftClicked}
        desktopRightHovered={desktopRightHovered}
        mobileRightClicked={mobileRightClicked}
        mobileLeftClicked={mobileLeftClicked}
        color={props.oColor}
        outlineColor={props.outlineColor}
        content="Digital"
        position={props.titlePosition}
      />

      <a.spotLight position={props.lightPos} intensity={props.intensity} color={COLORS.c06} />
      <a.spotLight intensity={props.intensity} position={props.lightPos02} color={COLORS.c01} />
      <a.spotLight intensity={props.intensity} position={props.lightPos03} color={COLORS.c06} />
      <a.rectAreaLight />
    </group>
  )
}

export default BoxL
