import React, { useRef, useEffect } from "react"
import { useThree } from "react-three-fiber"
import AnimatedText from "../_helpers/AnimatedText"
import { useSpring, a, config } from "react-spring/three"

function Title({
  color,
  content,
  outlineColor,
  roughness,
  layers,
  mobileLeftClicked,
  mobileRightClicked,
  desktopLeftHovered,
  desktopRightClicked,
  desktopLeftClicked,
  ...props
}) {
  const group = useRef()
  const { viewport } = useThree()

  const isIOS = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
  let fontSize = viewport.width * 0.1

  if (isIOS) {
    fontSize = viewport.width * 0.15
  }

  let isPos
  if (desktopRightClicked) {
    isPos = [0, 3.5, 0]
  } else if (desktopLeftClicked) {
    isPos = [0, 1.5, 0]
  }

  const aprops = useSpring({
    // Phone
    color: desktopLeftClicked || mobileLeftClicked ? "#e31d5b" : desktopLeftHovered ? "#e31d5b" : "#9e9e9e",
    position: desktopRightClicked
      ? [0, 3.5, 0]
      : desktopLeftClicked
      ? [0, 1.5, 0]
      : mobileLeftClicked
      ? [0, 0.0, 0]
      : mobileRightClicked
      ? [-1.25, 0, 0]
      : [0, 0, 0],
    // General Config
    config: {
      tension: 16,
      friction: 8,
    },
  })

  useEffect(() => {
    group.current.lookAt(0, 0, 90)
  }, [])

  return (
    <group {...props} ref={group}>
      <AnimatedText
        name="N"
        fontSize={fontSize}
        color={aprops.color}
        maxWidth="1"
        font="fonts/NewYork.woff"
        outlineWidth={0.005}
        outlineColor={aprops.color}
        position={aprops.position}>
        {content}
      </AnimatedText>
    </group>
  )
}
// <meshPhysicalMaterial metalness={1} roughness={1.5} color={color}  />

export default Title
