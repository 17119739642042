import React, { useRef } from "react"
import { useGLTF } from "@react-three/drei/core/useGLTF"
import { useFrame, useThree } from "react-three-fiber"
import { useSpring, a, config } from "react-spring/three"

import { MoveJoint, MoveJointNeck } from "../_helpers/MoveJoint.js"

import { CustomUniforms } from "../_helpers/CustomUniforms.js"
import { LineMaterial } from "../_shaders/threeMod/LineMaterial.js"
import { Galaxy } from "../_shaders/threeMod/Galaxy.js"

//
//
//  Original Code
//
//

export default function TorsoBoned({ mouse, desktopLeftClicked, desktopRightClicked, ...props }) {
  const group = useRef()
  const { nodes } = useGLTF("models/bonesd.glb")
  // const [thinFilmFresnelMap] = useState(new ThinFilmFresnelMap())
  // let tex = new ThinFilmFresnelMap(640, 2, 3, 64);
  const { viewport } = useThree()

  let ifPos = [-2.5, -6.6, -0.0]
  let ifRot = [0.0, 1.0, -0.1]
  if (desktopLeftClicked) {
    ifPos = [viewport.width / 4, -6.6, -0.5]
    ifRot = [0.0, -1.0, -0.1]
  }
  if (desktopRightClicked) {
    ifPos = [-viewport.width / 2.5, -6.6, -0.0]
    ifRot = [0.0, 0.85, -0.1]
  }

  const aprops = useSpring({
    // position: !desktopLeftClicked && !desktopRightClicked ? [-0.5, -6.6, -0.0] : [-0.5, -6.6, -0.0],
    // rotation: !desktopLeftClicked && !desktopRightClicked ? [-0.5, -6.6, -0.0] : [-0.5, -6.6, -0.0],

    position: desktopRightClicked || desktopLeftClicked ? ifPos : [-0.5, -6.6, -0.0],
    rotation: desktopRightClicked || desktopLeftClicked ? ifRot : [0.0, 0, -0.1],

    // position: desktopLeftClicked && !desktopRightClicked ? [2.5, -6.6, -0.0] : [-0.5, -6.6, -0.0],
    // rotation: desktopLeftClicked && !desktopRightClicked ? [0.0, -1.0, -0.1] : [0.0, 0, -0.1],

    config: {
      tension: 15,
      friction: 8,
    },
  })

  useFrame((state, delta) => {
    CustomUniforms.uTime.value += delta
    CustomUniforms.uTimeV.value += delta / 16
    CustomUniforms.transTime.value = mouse.x / 1500

    MoveJoint(mouse, nodes.Bone004)
    MoveJointNeck(mouse, nodes.Bone003)
  })

  // <meshStandardMaterial metalness={1} roughness={0.05} color={"black"}  skinning/>

  return (
    <group ref={group} {...props} dispose={null}>
      <a.group rotation={aprops.rotation} position={aprops.position} scale={[1.35, 1.35, 1.35]}>
        <primitive object={nodes.Bone} />
        <skinnedMesh
          material={Galaxy()}
          geometry={nodes.ParticleSettingscurve.geometry}
          skeleton={nodes.ParticleSettingscurve.skeleton}></skinnedMesh>
        <skinnedMesh
          material={LineMaterial(1.0)}
          geometry={nodes.ParticleSettingscurve_1.geometry}
          skeleton={nodes.ParticleSettingscurve_1.skeleton}></skinnedMesh>

        <skinnedMesh
          material={LineMaterial(1.0)}
          geometry={nodes.ParticleSettingscurve_2.geometry}
          skeleton={nodes.ParticleSettingscurve_2.skeleton}></skinnedMesh>
        <group>
          <skinnedMesh geometry={nodes.ParticleSettingscurve.geometry} skeleton={nodes.ParticleSettingscurve.skeleton}>
            <meshStandardMaterial metalness={1} roughness={0.05} color={"purple"} skinning wireframe />
          </skinnedMesh>
        </group>
      </a.group>
    </group>
  )
}

useGLTF.preload("models/bonesd.glb")

// <rectAreaLight intensity={1} position={[10, 0, 0]} width={10} height={1000} onUpdate={self => self.lookAt(new THREE.Vector3(0, 0, 0))} />

// material={LineMaterial(4.)}
// <meshPhysicalMaterial metalness="1"  roughness="0.05" color="purple" skinning/>
// <meshPhysicalMaterial metalness="1"  roughness="0.15" color="Red" skinning/>
