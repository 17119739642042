import React, { useRef, useState } from "react"
import { Box } from "@react-three/drei"
import { useFrame, useThree, useResource } from "react-three-fiber"
import { useSpring, a, config } from "react-spring/three"
import Plant from "../model/Plant.js"
import Title from "../elements/Title.js"
import { COLORS } from "../_helpers/Colors.js"
import { WaveFog } from "../_shaders/threeMod/WaveFog.js"
import { useLocation } from "wouter"

import Image from "../elements/Image.js"
import state from "../state.js"

const APlant = a(Plant)
const ABox = a(Box)
const ATitle = a(Title)

const isIOS = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

const isRealIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent)

function BoxL({ mobileRightClicked, mobileLeftClicked, desktopLeftHovered, desktopLeftClicked, desktopRightClicked }) {
  const group = useRef()
  const { viewport } = useThree()
  const [hovered, setHovered] = useState(false)
  let isPos, isBasePos, sOpac, sFog, flowerScale, flowerBaseScale, flowerRot, flowerBaseRot, mBoxPos

  isPos = [-viewport.width / 3, -1, -6.75]
  isBasePos = [-1, -7, -7.75]
  sFog = 125.0
  flowerScale = [0.16, 0.16, 0.16]
  flowerBaseScale = [0.07, 0.07, 0.07]
  flowerRot = [2.4, 0.1, -0.2]
  flowerBaseRot = [2.5, 3.2, 3.5]
  mBoxPos = [2, 0, 0]

  if (desktopRightClicked) {
    isPos = [-viewport.width / 3, -10, -15.75]
    isBasePos = [-viewport.width / 3, -10, -15.75]
  } else if (desktopLeftHovered) {
    isPos = [-viewport.width / 3, -10, -15.75]
    isBasePos = [-viewport.width / 3, -1, -6.75]
    flowerBaseRot = [2.4, 0.1, -0.2]
    flowerBaseScale = [0.16, 0.16, 0.16]
  } else if (desktopLeftClicked) {
    isPos = [-viewport.width / 3, -10, -15.75]
    isBasePos = [15, -2, -15.75]
  }

  const props = useSpring({
    position: hovered ? isPos : isBasePos,

    scale: hovered ? flowerScale : flowerBaseScale,

    rotation: hovered ? flowerRot : flowerBaseRot,

    boxPosition: desktopLeftClicked
      ? [viewport.width * 0.5, 0, -7]
      : mobileLeftClicked
      ? [viewport.width * 0.3, 0, -7]
      : mobileRightClicked
      ? [-viewport.width * 2, 0, -7]
      : isIOS
      ? [-viewport.width, 0, -8]
      : [-viewport.width / 1.05, 0, -8],

    boxScale: desktopLeftClicked
      ? [3.0, 1.0, 1.0]
      : mobileLeftClicked
      ? [2.0, 1.0, 1.0]
      : mobileRightClicked
      ? [0.0, 1.0, 1.0]
      : [1.0, 1.0, 1.0],
    boxArgs: isIOS ? [viewport.width * 2.0, viewport.height * 5.5] : [viewport.width * 4.0, viewport.height * 5.5],

    boxRotation: isIOS ? [0.0, 0.0, 0.0] : [0.0, 0.0, -0.2],

    positionIOS: mobileLeftClicked ? [-viewport.width / 3.0, -1.5, -5.75] : [-1, -7, -7.75],
    scaleIOS: mobileLeftClicked ? [0.1, 0.1, 0.1] : [0.07, 0.07, 0.07],
    rotationIOS: [2.2, 0.0, 1.2],

    mobileBoxPos: desktopLeftClicked ? [-viewport.width * 2, 0, -8] : [-viewport.width * 2, 0, -8],

    // Text
    color: hovered ? COLORS.white : COLORS.white,
    colorMobile: mobileLeftClicked ? COLORS.c05 : "#E2E2E2",
    oColor: hovered ? COLORS.c05 : "#E2E2E2",
    outlineColor: hovered ? COLORS.c05 : "#E2E2E2",

    outlineColor: mobileLeftClicked ? COLORS.c05 : "#E2E2E2",
    titlePosition:
      isIOS && !isRealIOS
        ? [-viewport.width / 2.8, 2.7, -1.0]
        : isRealIOS
        ? [-viewport.width / 2.8, 2.2, -1.0]
        : [-viewport.width / 3.5, 0.75, -0.1],

    // Light
    intensity: hovered ? 25 : 0,
    lightPos: hovered ? [-7, 0, -2] : [1, 0, 0],
    lightPos: hovered ? [-4, 3, -2] : [1, 0, 0],

    //shader opacity
    shaderOpacity: hovered ? 0.2 : desktopLeftClicked ? 0.0 : 0.2,
    shaderOpacityMobile: 0.1,
    fogC: hovered ? 45.0 : desktopLeftClicked || mobileLeftClicked ? 1500.0 : 125.0,
    shaderColorR: hovered ? 1.0 : 0.7,
    shaderColorG: hovered ? 0.1 : 0.1,
    shaderColorB: hovered ? 0.4 : 0.2,

    v01: hovered ? [-viewport.width / 1.77, 4, -5.5] : [-viewport.width / 5, -4, -4.5],
    r01: hovered ? [3, 2.7, 3.5] : [3, 2, 2.75],

    v02: hovered ? [-viewport.width / 1.25, -3, -4.5] : [-viewport.width / 5, -4, -3.5],

    // General Config
    config: {
      tension: 16,
      friction: 8,
    },
  })

  const [location, setLocation] = useLocation()

  function navigate() {
    setLocation("/fashion")
  }

  return (
    <group ref={group} onPointerOver={() => setHovered(true)} onPointerOut={() => setHovered(false)}>
      <ABox
        material={WaveFog(props.shaderOpacity, props.fogC, props.shaderColorR, props.shaderColorG, props.shaderColorB)}
        rotation={props.boxRotation}
        args={[viewport.width * 2.0, viewport.height * 5.5]}
        scale={props.boxScale}
        position={props.boxPosition}
        desktopLeftHovered={desktopLeftHovered}
      />
      {/* 
      {isIOS ? (
        <Title
          color={props.colorMobile}
          outlineColor={props.outlineColor}
          mobileLeftClicked={mobileLeftClicked}
          content="Fashion"
          position={[-viewport.width / 2.8, 2.7, -1.0]}
        />
      ) : (
     
      )} */}

      <ATitle
        desktopRightClicked={desktopRightClicked}
        desktopLeftClicked={desktopLeftClicked}
        desktopLeftHovered={desktopLeftHovered}
        mobileLeftClicked={mobileLeftClicked}
        mobileRightClicked={mobileRightClicked}
        color={props.oColor}
        outlineColor={props.outlineColor}
        content="Fashion"
        position={props.titlePosition}
      />

      {isIOS ? null : <APlant position={props.position} rotation={props.rotation} scale={props.scale} />}
      <a.spotLight position={props.lightPos} color={COLORS.c02} intensity={props.intensity} />
      <a.spotLight position={props.lightPos} color={COLORS.c05} intensity={props.intensity} />
    </group>
  )
}

export default BoxL
