import React from "react"

function FashionContent() {
  return (
    <div className="content-section">
      <div className="content-wrap">
        <div className="content-image">
          <svg width="46" height="54" viewBox="0 0 46 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1604_18)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.4215 0L24.2545 1.69853C24.4283 2.05288 24.8154 2.55932 25.3979 3.25978C25.5356 3.42517 25.682 3.59915 25.8345 3.78032C26.2929 4.32492 26.8061 4.93463 27.2973 5.57195C28.5811 7.23767 29.9412 9.36496 29.9412 11.6182C29.9412 13.7948 29.1246 15.4619 27.953 16.9354C27.1231 17.9795 26.0642 18.9808 24.985 20.0016C24.8257 20.1522 24.666 20.3032 24.5064 20.4549C25.8009 21.7829 27.4663 23.3541 28.5951 24.1213L27.5617 25.7014C26.2683 24.8222 24.4793 23.1238 23.1501 21.7577C21.4221 23.3844 19.673 24.8021 18.3499 25.7014L17.3166 24.1213C18.5342 23.2938 20.1919 21.9545 21.8505 20.3963C21.7143 20.2552 21.578 20.1144 21.4419 19.974C20.4521 18.9529 19.4794 17.9492 18.7176 16.9038C17.6413 15.427 16.9019 13.7706 16.9019 11.6182C16.9019 9.36496 18.262 7.23767 19.5458 5.57195C20.037 4.93463 20.5501 4.32492 21.0086 3.78032C21.1611 3.59915 21.3075 3.42517 21.445 3.25978C22.0275 2.55932 22.4146 2.05288 22.5884 1.69853L23.4215 0ZM23.2069 19.0929C23.4127 18.8965 23.6145 18.7057 23.8116 18.5193C24.8522 17.535 25.7654 16.6711 26.5059 15.7397C27.4933 14.4979 28.0783 13.2397 28.0783 11.6182C28.0783 10.0735 27.1101 8.40276 25.8326 6.7452C25.3685 6.14318 24.8941 5.57941 24.4417 5.04194C24.2838 4.85421 24.1284 4.66967 23.9772 4.48785C23.7878 4.26002 23.5991 4.03029 23.4215 3.80276C23.2438 4.03029 23.0553 4.26002 22.8659 4.48785C22.7147 4.66967 22.5593 4.85421 22.4014 5.04194C21.949 5.57941 21.4744 6.14318 21.0105 6.7452C19.733 8.40276 18.7646 10.0735 18.7646 11.6182C18.7646 13.2639 19.3102 14.5327 20.2128 15.7713C20.8906 16.7014 21.7257 17.563 22.6789 18.5468C22.8512 18.7244 23.0272 18.9061 23.2069 19.0929Z"
                fill="#B8A067"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.86276 24.4293C3.86276 21.0196 6.60118 18.2408 9.99701 18.2408V16.3418C5.58836 16.3418 2 19.9545 2 24.4293C2 27.7926 4.18865 31.045 6.65692 32.9855V30.6081H6.80922C5.11429 28.9172 3.86276 26.6127 3.86276 24.4293Z"
                fill="#B8A067"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.25687 22.7324L21.5588 39.6522V43.9016H21.4603L7.85124 25.3379H7.78734L7.94707 26.3474L8.01096 43.9016H6.47754V22.7324H9.25687Z"
                fill="#B8A067"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.1519 20.94C32.5684 19.4886 34.4866 18.2408 36.8468 18.2408V16.3418C33.8035 16.3418 31.4382 17.9542 29.8314 19.6006C28.2334 21.2379 27.2791 23.015 27.0492 23.6171C26.7252 24.4658 26.5206 25.223 26.3964 25.7703C26.3342 26.0444 26.2918 26.2674 26.2646 26.4248C26.251 26.5034 26.2413 26.5658 26.2346 26.6101L26.2271 26.6627L26.2264 26.6676C25.8377 29.0725 26.5106 32.0863 28.0829 34.8787L29.6986 33.9333C28.2716 31.3989 27.7623 28.8238 28.066 26.966L28.0687 26.9495L28.0706 26.9346L28.0714 26.9284L28.0762 26.8959C28.0807 26.8655 28.0882 26.8175 28.0992 26.754C28.1211 26.6266 28.1571 26.4367 28.2111 26.1983C28.3197 25.7205 28.4998 25.0531 28.7852 24.3058C28.9178 23.9584 29.7265 22.4004 31.1519 20.94Z"
                fill="#B8A067"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M36.8466 18.2408C40.2423 18.2408 42.9808 21.0196 42.9808 24.4293C42.9808 25.6595 42.4484 26.6588 41.4476 27.5228C40.4204 28.4097 38.9258 29.1248 37.115 29.7017C34.5876 30.5069 30.7927 32.0042 27.6101 33.8942C26.021 34.8378 24.5422 35.9039 23.4493 37.062C22.3689 38.2069 21.5591 39.556 21.5591 41.0525H23.4218C23.4218 40.2703 23.8539 39.3724 24.7916 38.3788C25.7167 37.3986 27.0321 36.4351 28.5476 35.5351C31.5742 33.7378 35.2305 32.2916 37.6707 31.5143C39.5852 30.9043 41.3503 30.0954 42.6513 28.9721C43.9789 27.826 44.8437 26.3293 44.8437 24.4293C44.8437 19.9545 41.2552 16.3418 36.8466 16.3418V18.2408Z"
                fill="#B8A067"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.0721 20.1154C12.7616 18.908 11.4041 18.2408 9.99707 18.2408V16.3418C12.0199 16.3418 13.8031 17.308 15.3211 18.7066C16.8369 20.1031 18.1521 21.9872 19.2675 23.9776C20.8965 26.8847 21.9535 29.2312 22.5883 32.2038C23.2174 35.1507 23.422 38.6676 23.422 43.9011H21.5593C21.5593 38.6904 21.3524 35.3444 20.7682 32.6078C20.1894 29.8972 19.2303 27.7396 17.6501 24.9195C16.5853 23.0195 15.3847 21.3249 14.0721 20.1154Z"
                fill="#B8A067"
              />
            </g>
            <defs>
              <clipPath id="clip0_1604_18">
                <rect width="43" height="44" fill="white" transform="translate(2)" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className="content-text">
          <div className="title">
            <h2>Fashion-Designer</h2>
            <h3>Selbständig - NUOMX</h3>
          </div>
          <p className="description">
            In meiner Selbstständigkeit war ich für die Erstellung von Kollektionsunterlagen und Moodboards, die Entwicklung von
            passformsicheren und umsatzstarken Kollektionen, die Teilnahme an Musterung- und Produktionsanproben, die Trendanalyse, Markt-
            und Mitbewerberbeobachtung durch Storechecks und Messebesuche, Designentwürfe sowie Material- und Farbauswahl, die Betreuung und
            Pflege des Onlineshops sowie die Planung und Durchführung von Shootings zuständig.
          </p>
        </div>
      </div>
      <div className="content-wrap">
        <div className="content-image">
          <svg width="46" height="54" viewBox="0 0 46 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M34.5174 41.3039H39V23.419H26.6864V23.9545H34.5174V26.2035C34.2474 35.7349 30.0888 41.4645 24.0401 41.4645C17.0192 41.4645 12.5366 33.7537 12.5366 22.241C12.5366 10.6212 17.0192 2.53547 24.4181 2.53547C29.8188 2.53547 32.7352 6.81928 34.7334 13.1914H39C36.3537 6.2838 31.277 2 24.1481 2C14.4268 2 8 10.0321 8 21.9732C8 33.7537 14.3188 42 24.2021 42C28.4686 42 31.9791 40.4471 34.5174 37.7162V41.3039Z"
              fill="black"
            />
          </svg>
        </div>
        <div className="content-text">
          <div className="title">
            <h2>JR. Designerin</h2>
            <h3>Pelzdesign Geissler, Essen</h3>
          </div>
          <p className="description">
            Bei Pelzdesign Geissler konnte ich meine Fähigkeiten im Bereich der Verarbeitung und Upcycling von Materialien verbessern und
            erweitern. Meine Tätigkeitsschwerpunkte umfassten auch das Erstellen von Designlösungen, das Verständnis von
            Projektanforderungen und -konzepten sowie die Präsentation kreativer Ideen im Bereich der Pelz- und Textildesigns.
          </p>
        </div>
      </div>
      <div className="content-wrap">
        <div className="content-image">
          <svg width="46" height="52" viewBox="0 0 46 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="46" height="52" fill="#C50D15" />
            <path
              d="M16 18.25L9.5 9H27H31.5C37.0228 9 41.5 13.4772 41.5 19V22.25C41.5 27.7728 37.0228 32.25 31.5 32.25H27L34.5 43.25L4 23.75H27.5V18.25H16Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="content-text">
          <div className="title">
            <h2>Assistent Qualitätsmanagement</h2>
            <h3>Red Star, Istanbul</h3>
          </div>
          <p className="description">
            Ich hatte bei Redstar in Istanbul die Möglichkeit, meine Fähigkeiten in den Bereichen technische Dokumentation,
            Qualitätskontrolle, Materialwissenschaften, Qualitätsmanagement und internationales Geschäft zu verbessern und zu erweitern.
            Meine Tätigkeitsschwerpunkte umfassten die Erstellung von Verarbeitungshinweisen und Maßtabellen, die Erarbeitung von
            Prüfstandards/Qualitätsabnahmen, die Qualitätssicherung der Materialien und die Betreuung von internationalen Kunden.
          </p>
        </div>
      </div>
    </div>
  )
}

export default FashionContent
